table:not(.table-condensed) {
    margin: 1rem 0 2rem;
    position: relative;
    border-collapse: collapse;
    border: solid $mintgreen .125rem;
    border-radius: 1rem;
    overflow: hidden;

    td,
    th {
        padding: .5rem;
    }

    th {
        background-color: $mintgreen;
    }

    tr:nth-child(even) {
        background-color: $winewhite;
    }

}