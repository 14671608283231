.gstripe {
    &__container {
        position: relative;
        margin: 0 !important;
        padding: 0 !important;
        display: flex;
        justify-content: center;

        align-items: center;
        height: 60vh;
        color: $winewhite;

        overflow: hidden;

        @media (min-width: 768px) {
            height: 80vh;
        }

        &__image {
            position: absolute;
            z-index: 0;
            transition: all .125s linear;
            width: 100%;
            height: 100%;
            object-fit: cover;
            top: 0;
        }

        &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            background: linear-gradient(45deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%);
            opacity: 0;
            z-index: 1;
            transition: all .125s;
        }

        &:hover:before {
            opacity: 1;
        }

        &:hover &__image {
            transform: scale(1.125);
        }

        &__label {
            position: relative;
            z-index: 2;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 2rem;
            top: 0;
            transition: top .25s;

            &__span {
                @extend .text-dancing;
                font-size: 4rem;
                transform: rotate(-12deg);

                font-weight: 900;

                @media (min-width: 992px) {
                    font-size: 5rem;
                }
            }

            &__link {
                font-size: 4rem;
                opacity: 0;
                transform: scale(0);
                transition: all .125s;
                transition-delay: .25s;

                &:hover {
                    color: $mintgreen;
                    @extend .animated;
                    @extend .tada;
                }
            }

            i {
                @extend .text-bad;
                font-size: 1.5rem;
                font-weight: 900;

                @media (min-width: 992px) {
                    font-size: 2rem;
                }
            }

            p {
                text-align: center;
                opacity: 0;
                height: 0;
            }
        }

        &:hover &__label {
            top: -2rem;

            p {
                @extend .animated;
                @extend .fadeIn;
                height: auto;
            }

            a {
                opacity: 1;
                transform: scale(1);
            }
        }
    }
}