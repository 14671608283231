$bg-color: #BA265D;
$lipstick: #cc2e2e;
$color: #343434;
$yellow: #ffeb00;
$mintgreen: #2ecc71;
$coal: #46647c;
$snowflake: rgb(180, 180, 180);
$winewhite: #f4f4f4;
$white: #FFF;
$babygreen: #1abc9c;
$orange: #e29911;

@import url('https://fonts.googleapis.com/css?family=Dancing+Script&display=swap');
@import url('https://fonts.googleapis.com/css?family=Bad+Script&display=swap');
@import url('https://fonts.googleapis.com/css?family=Oswald&display=swap');

@import '_animate';
@import '_cards';
@import '_gstripe';
@import '_instagram';
@import '_overrides';
@import '_faqs';
@import '_cookie';
@import '_fonts';
@import '_table';
@import '_vue';

.color {
    &--green {
        color: $mintgreen;
    }
    &--orange {
        color: $orange;
    }
}

body {
    background-size: cover;
    color: $color;
}

.iconlink {

    &,
    &:hover {
        text-decoration: none;
    }
}

/* Nav */
.navbar-nav {
    a {
        transition: color .125s;

        &:hover {
            color: $mintgreen;
            text-decoration: none;
        }
    }

    .active {
        color: $mintgreen;
    }

    .languageSwitch {
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 1rem;

        i {
            font-size: 1.25rem;
        }

        @media (min-width: 992px) {
            justify-content: center;
            align-items: center;
            margin-top: 0;
            font-size: .9rem;

            i {
                font-size: .9rem;
            }
        }

        a {
            padding: 0 .25rem;
            margin: 0;
        }

    }
}

.form {
    &_email {
        border: 1px solid red;
        position: absolute;
        top: -5000vh;
    }

    &_name {
        border: 1px solid green;
        position: absolute;
        top: -5000vh;
    }
}

.teaser {
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    min-height: 100vh;
    color: #FFF;
    overflow: hidden;
    padding-top: 70px;
    padding-bottom: 10rem;

    &:before {
        content: "";
        width: 100%;
        height: 100%;
        background: rgb(0, 0, 0);
        background: linear-gradient(152deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);

        position: absolute;
        top: 0;
        left: 0;
        opacity: .5;
    }

    &--page {
        height: 420px;
        min-height: auto;
    }

    &__content {
        width: 100%;
    }

    &__notification {
        z-index: 100;
        position: fixed;
        top: 57px;
        left: 0;
        width: 100%;
        padding: .5rem;
        background: $mintgreen;
        color: $winewhite;
        box-shadow: 0 0 5px $color;

        @media (min-width: 992px) {
            top: 70px;
        }

        a {
            color: $coal;
        }
    }
}

.zigzag {
    width: 100%;
    height: 150px;
    position: absolute;
    transform: scaleY(-1);
    bottom: -1px;

    &__shape {
        width: 100%;
        height: 100%;

        svg {
            display: block;
            height: 100%;
            width: 100%;
        }
    }
}

.checkin {
    &__form {
        background: rgba(0, 0, 0, 0.5);
        padding: 0 2rem 2rem;
        border-radius: 1rem;
    }
}

/* WTF CARD */
.wtf {
    &__card {
        &__icon {
            font-size: 3.5rem;
            color: $mintgreen;
        }

        &__headline {
            font-size: 2.5rem;
            font-family: 'Dancing Script', cursive;
        }

    }
}

/* BTN */
.btn {

    &--green {
        background-color: $mintgreen;
        color: #FFF;
    }

    &--red {
        background-color: $lipstick;
    }

    &--yellow {
        background-color: $yellow;
    }

    &-round {
        border-radius: 100%;
        width: 10rem;
        height: 10rem;
        font-size: 3rem;
        line-height: 3.25rem;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        font-weight: 100;
        position: relative;

        span {
            z-index: 2;
        }

        &:before {
            content: "";
            width: 4rem;
            height: 4rem;
            background: $mintgreen;
            display: block;
            bottom: 0;
            right: 0;
            border-radius: 100%;
            position: absolute;
            z-index: 0;
        }
    }
}

a {
    color: $mintgreen;
    text-decoration: none;
    transition: color .125s;

    &:hover {
        color: $color;
    }
}

/* PADDINGS */
.p-5 {
    padding: 1rem !important;

    @media (min-width: 992px) {
        padding: 3rem !important;
    }
}

.p-10 {
    padding: 2rem 0 !important;

    @media (min-width: 992px) {
        padding: 5rem 0 !important;
    }
}

.pb-10 {
    padding-bottom: 10rem;
}

/* FLOATY */
.floaty {

    background: url('../img/road.jpg') no-repeat bottom;
    background-size: cover;

    p {
        font-size: 1.5rem;
        color: #FFF;
    }

    small {
        color: $mintgreen;
        font-size: .95rem;
    }
}

/* NAVBAR */
.navbar {
    a {
        color: $color;
        padding: 1rem;
        margin-right: 1.5rem;
    }
}

/* PRICING */

.pricing .card {
    border: none;
    border-radius: 1rem;
    transition: all 0.2s;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
}

.card {
    &__image {
        max-width: 100%;
    }
}

.pricing hr {
    margin: 1.5rem 0;
}

.pricing .card-title {
    margin: 0.5rem 0;
    font-size: 0.9rem;
    letter-spacing: .1rem;
    font-weight: bold;
}

.pricing .card-price {
    font-size: 3rem;
    margin: 0;
}

.pricing .card-price .period {
    font-size: 0.8rem;
}

.pricing ul li {
    margin-bottom: 1rem;
}

.pricing .text-muted {
    opacity: 0.7;
}

.pricing .btn {
    font-size: 80%;
    border-radius: 5rem;
    letter-spacing: .1rem;
    font-weight: bold;
    padding: 1rem;
    opacity: 0.7;
    transition: all 0.2s;
}

/* Hover Effects on Card */

@media (min-width: 992px) {
    .pricing .card:hover {
        margin-top: -.25rem;
        margin-bottom: .25rem;
        box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.3);
    }

    .pricing .card:hover .btn {
        opacity: 1;
    }
}

.list {
    list-style: none;
    margin: 0;
    padding: 1rem 0;
    font-size: 1.125rem;

    span {
        font-size: 1.5rem;
        margin-right: .5rem;
    }

    li {
        display: flex;
        align-items: center;
        padding-bottom: .5rem;

        i {
            margin-right: .5rem;
        }
    }

    &--small {
        font-size: .8rem;
        width: 50%;
        float: left;

        span,
        i {
            font-size: 1rem;
            margin-right: .5rem;
        }
    }

    &--social {
        display: flex;
        font-size: 1.5rem;

        &--center {
            width: 100%;
            justify-content: center;
        }

        li {
            a {
                padding: 0 .5rem;

                &:hover {
                    color: inherit;
                    @extend .animated;
                    @extend .tada;
                }
            }
        }

        &--coal {
            li a {
                color: $coal;
            }
        }

        &--main {
            padding: 0;

            li a {
                padding: 0;
            }
        }
    }
}

.zig {
    &--lime {
        background: $mintgreen;
    }

    &--winewhite {
        background: $winewhite;
    }

    &--white {
        background: $white;
    }

    position: relative;


    &--top {
        width: 100%;
        height: 100px;
        position: absolute;
        top: -100px;
        left: 0px;

        &--white {
            background: linear-gradient(to left bottom, transparent 49%, $white 50%);
        }

        &--winewhite {
            background: linear-gradient(to left bottom, transparent 49%, $winewhite 50%);
        }

        &--lime {
            background: linear-gradient(to left bottom, #fff 49%, $mintgreen 50%);
        }


        &--rotate {
            transform: rotate(180deg) scaleX(-1);
            top: 0;
            z-index: 3;
        }
    }

    &--bottom {
        width: 100%;
        height: 100px;
        position: absolute;
        left: 0px;

        &--white {
            background: linear-gradient(to right bottom, $white 49%, transparent 50%);
        }

        &--winewhite {
            background: linear-gradient(to right bottom, $winewhite 49%, #fff 50%), linear-gradient(-50deg, #ffffff 16px, #000 0);
        }

        &--lime {
            background: linear-gradient(to right bottom, $mintgreen 49%, #fff 50%), linear-gradient(-50deg, #ffffff 16px, #000 0);
        }

        &--rotate {
            transform: rotate(180deg);
            bottom: 0;
            z-index: 3;
        }
    }
}

.bg {
    position: relative;
    z-index: 1;

    &:before {
        content: "";
        width: 100%;
        height: 60%;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 0;
        background-repeat: no-repeat;
        background-position: left;
        background-size: auto 100%;
        opacity: .5;
    }

    &--elk:before {
        background-image: URL('../img/elk.png');
    }
}

.sightseeingTable {
    &__list {
        display: flex;
        list-style: none;
        align-items: center;
        justify-content: space-between;
        margin: 0;
        padding: 0;
        font-size: .9rem;
        color: $coal;

        li {
            display: flex;

            i {
                margin-right: .5rem;
                font-size: 1.25rem;
            }
        }
    }
}

/* CARCHOOSE */
.carchoose {
    overflow: hidden;

    @media (min-width: 576px) {
        padding-top: 5rem;
    }

    &__headline {
        @extend .text-bad;
        font-size: 2rem;
        display: block;

        @media (min-width: 768px) {
            transform: rotate(-45deg) translate(40px, -40px);
        }
    }
}

/* FOOTER */
.footer {
    background: URL('../img/forest-footer.jpg') no-repeat bottom;
    background-size: cover;
    color: $snowflake;
    text-align: center;

    @media (min-width: 768px) {
        text-align: left;
    }

    h1 {
        font-size: 1.5rem;
    }
}

/* Newsletter */
.signup {
    position: relative;
    background: $mintgreen;
    display: flex;
    flex-direction: row;
    width: 100%;

    &:before {
        content: "";
        background-color: $mintgreen;
        background-image: URL("../img/newsletter.jpg");
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: .5;
    }

    form {
        display: flex;
        width: 100%;
        position: relative;
    }

    input {
        width: 100%;
        height: 3rem;
        font-size: 1rem;
        padding: 0 2rem 0 1rem;
        background: $winewhite;
        border: none;

        @media (min-width: 992px) {
            font-size: 1.5rem;
            height: 3.5rem;
        }
    }

    .btn {
        height: 3rem;
        margin: 0;
        margin-left: -1.5rem;
        position: absolute;
        right: 0;

        @media (min-width: 992px) {
            height: 3.5rem;
        }

        &:hover {
            @extend .animated;
            @extend .tada;
        }
    }

    input,
    .btn {
        border-radius: 1rem;
    }


}

.align_right {
    float: right;
    margin: 1rem;
    margin-right: 0;
}

.align_left {
    float: left;
    margin: 1rem;
    margin-left: 0;
}

.form-control {
    &.error {
        color: $lipstick;
    }
}

.container {
    &-large {
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
    }
}