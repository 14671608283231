.vue {
  &__BookingForm {
    i {
      font-size: 1.25rem;
    }
    .BookingForm {
      display: flex;
      background: #fff;
      border-radius: 0.75rem;
      border: 1px solid #ddd;
    }

    .col {
      padding: 0;
      flex: 0 0 25%;
      max-width: 25%;
    }

    input,
    select,
    button {
      border-radius: 0;
      border: none;
      box-shadow: 0;
      height: 44px;
      font-size: 1.125rem;
    }
    .input-group-text {
      background: none;
      border: none;
    }

    .border {
      border-left: 1px solid #ccc;
    }

    .btn-lg {
      border-radius: 0 0.75rem 0.75rem 0;
    }
  }

  &__CarsList {
    .card {
      margin-top: 5rem;
    }

    .CarsList {
      &__btn {
        padding: 1rem;

        .btn {
          margin-bottom: 1rem;
        }
      }
    }

    .card {
      &-body {
        padding: 1rem 1.5rem;
      }
    }

    .card__carchoose__image {
      width: 260px;
    }
  }

  &__BookingCard {
    width: 100%;

    position: sticky;
    top: 80px;

    .BookingCard__row {
      border-bottom: 1px solid #eee;
      padding-bottom: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }

  &__BookingExtras {
    .card {
      font-size: 0.9rem;
      cursor: pointer;
      transition: all 0.125s;
      &.selected,
      &:hover {
        border-color: #2ecc71;
        box-shadow: 0 0 3px #2ecc71;
      }

      &-deck {
        margin-top: 2rem;
      }

      &:nth-child(2) {
        transform: translateY(-1rem);
      }

      img {
        width: 169px;
        height: 200px;
        object-fit: contain;
      }
    }
    .Buttons {
      font-size: 1.5rem;
      &__btn {
        border: none;
        background: none;
      }
    }
  }

  &__Stripe {
    .stripe-card {
      width: 300px;
      border: 1px solid grey;
    }
    .stripe-card.complete {
      border-color: green;
    }

    /* Padding for Stripe Element containers */
    .stripe-element-container {
      padding-top: 0.55rem;
      padding-bottom: 0.5rem;
    }

    /* Blue outline on focus */
    .StripeElement {
      @extend .form-control;
    }

    /* Can't see what I type without this */
    #card-number.form-control,
    #card-cvc.form-control,
    #card-exp.form-control {
      display: inline-block;
      @extend .form-control;
    }
  }
}
