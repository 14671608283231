/* CookieConsent */
.pwcmb {
    right: 0;
    left: auto;
    font-size: .75rem;
    width: 100%;
    @extend .animated;
    @extend .bounceInUp;

    @media (min-width: 992px) {
        width: 360px;
    }
}

.pwcmb-widget {
    border: 1px solid $babygreen;
    padding: .5rem;
}

.pwcmb-widget__inner {
    flex-direction: column;
    align-items: flex-start;
}

.pwcmb-widget__actions {
    margin-left: 0;
    padding: 0;
}

.pwcmb-widget button {
    padding: 5px 20px;
    border-radius: 20px;
    background: $babygreen;
    border: none;
    color: $white;

    &:hover {
        @extend .animated;
        @extend .tada;
    }
}

.pwcmb-widget__close {
    position: absolute;
    top: -5px;
    right: -5px;

    &::before {
        font-family: 'Font Awesome 5 Free';
        content: "\f057";
        font-size: 1.5rem;
        color: $babygreen;
    }
}