.card {
    transition: transform .125s;

    &--linked {
        text-decoration: none;
        color: $color;

        &:hover {
            text-decoration: none;

            .card {
                border-color: $mintgreen;
            }
        }
    }

    &--up {
        @media (min-width: 768) {
            transform: translateY(-70px);
        }
    }

    img {
        max-width: 100%;
    }

    &__carchoose {
        &__image {
            transform: translate(0px, -40px) scale(1.12);
            margin-bottom: -40px;
            transition: all .125s;
        }

        &:hover {
            border-color: $mintgreen;
        }

        &:hover &__image {
            transform: translate(0px, -40px) scale(1.2);
        }
    }

}