.instagram {
    padding-top: 5rem;
    &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__headline {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        @extend .text-dancing;
        font-size: 1.75rem;
        transform: rotate(-45deg);

        @media (min-width: 992px) {
            font-size: 2.5rem;
        }

        i {
            margin-right: .5rem;
        }

        &:hover {
            text-decoration: none;
        }
    }
}