.navbar {
    background: $white;
    @extend .text-oswald;
    letter-spacing: .075rem;
    text-transform: uppercase;
    border-bottom: 1px solid $mintgreen;

    .nav-item a {
        display: flex;
        align-items: center;
    }

    .lnr {
        font-size: 1.5rem;
    }
}

.btn {
    background: $mintgreen;

    &-primary {
        border: none;
    }
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
    background-color: $mintgreen;
    border-color: transparent;
    color: $color;
}

.bg {
    &-success {
        background-color: $mintgreen !important;
    }

    &-babyblue {
        background-color: lightblue;
    }
}

.breadcrumb {
    background: none;
    opacity: .5;
    transition: all .125s;

    &:hover {
        opacity: 1;
    }
}