.text-dancing {
    font-family: 'Dancing Script', cursive;
}

.text-bad {
    font-family: 'Bad Script', cursive;
}

.text-oswald {
    font-family: 'Oswald', sans-serif;
}

.text {
    &--green {
        color: $mintgreen;
    }
}

h1,
h2,
.h1 {
    font-family: 'Dancing Script', cursive;
    font-size: 4rem;

    @media (min-width: 992px) {
        font-size: 8rem;
    }
}

h2,
.h2 {
    font-size: 2rem;

    @media (min-width: 992px) {
        font-size: 3rem;
    }
}

h3 {
    font-size: 2rem;
    font-weight: 100;

    @media (min-width: 992px) {
        font-size: 2rem;
    }
}

h4 {
    font-size: 1rem;

    @media (min-width: 992px) {
        font-size: 2rem;
    }
}

p {
    img {
        max-width: 100% !important;
    }
}

figcaption {
    font-size: .75rem;
    opacity: .75;
}